import { rgba } from 'polished';

import * as colors from './colors';
import { responsiveStyles } from './util';

import './fonts';
import { mq } from 'styles';

// Place global Typography in this file
export const primaryFont = 'FT Regola Neue, -apple-system, serif';
export const secondaryFont = 'Mackinac, -apple-system, serif';
export const medium = 500;
export const bold = 700;

export const display = `
${responsiveStyles('font-size', 96, 96, 52, 52)};
${responsiveStyles('line-height', 104, 104, 57, 57)};
${responsiveStyles('letter-spacing', '-2%','-2%','-1%',"-1%")};
  font-family: ${primaryFont};
  font-style: normal;
`;

export const h4 = `
font-size: 18px;
line-height:24px
font-weight: 500;
font-family: ${primaryFont};
font-style: normal;
  margin:0;
`;

export const h3 = `
  font-size: 28px;
  font-weight: 600px;
  line-height:33.6px;
  letter-spacing:-0.5%;
  font-family: ${primaryFont};
  margin:0;
`;

export const tableHead = `
    font-weight: 500px;
    font-size: 16px;
    line-height: 20px;
    font-family: ${primaryFont};
    margin:0
`
export const tableMainField = `
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    font-family: ${primaryFont};
    margin:0
`
export const tableData = `
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    font-family: ${primaryFont};
    margin:0
`
export const bodyMd = `
  ${responsiveStyles('font-size', 18, 18, 16, 16)};
  ${responsiveStyles('line-height', 24, 24, 20, 20)};
  font-family: ${primaryFont};
  font-weight: 400;
`;

export const heading = `
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  font-family: ${primaryFont};
`
