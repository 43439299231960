/* eslint-disable react/jsx-fragments */
import React from 'react';

import styled from '@emotion/styled';
import { TransitionGroup, Transition } from 'react-transition-group';
import { colors } from 'styles';

interface PageContentProps {
  transitionStatus: any;
}

interface TransitionOverlayProps {
  style: React.CSSProperties;
  effect: string; // Define the type for the effect prop
  overlay: any;
  transitionStatus: any;
  bgColor: any;
}

const timeout = 500;
const hang = 0;

const PageContent = styled.div<PageContentProps>`
  // transition: opacity ${timeout}ms ease-in-out;
  display: flex;
  flex-direction: column;
  ${({ transitionStatus }: any) => transitionStatus === 'entering' && `
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    opacity: 0;
  `}
  ${({ transitionStatus }: any) => transitionStatus === 'entered' && `
    opacity: 1;
  `}
  ${({ transitionStatus }: any) => transitionStatus === 'exiting' && `
    opacity: 0;
    opacity: 1;
  `}
  ${({ transitionStatus }: any) => transitionStatus === 'exited' && `
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    opacity: 0;
  `}
`;

const TransitionOverlay = styled.div<TransitionOverlayProps>`
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
  background: ${({ bgColor }: any) => bgColor};
  ${({ overlay, effect }: any) => effect === 'fade' && `
    transition: opacity ${timeout}ms ease-in-out;
    opacity: 0;
    ${overlay && 'opacity: 1'}
  `}
  ${({ overlay, effect }: any) => effect === 'wipeUp' && `
    transition: transform ${timeout}ms ease-in-out;
    transform-origin: 50% 0%;
    transform: scaleY(0);
    ${overlay && `
      transform: scaleY(1);
      transform-origin: 50% 100%;
    `}
  `}
  ${({ overlay, effect }: any) => effect === 'wipeDown' && `
    transition: transform ${timeout}ms ease-in-out;
    transform-origin: 50% 100%;
    transform: scaleY(0);
    ${overlay && `
      transform: scaleY(1);
      transform-origin: 50% 0%;
    `}
  `}
  ${({ overlay, effect }: any) => effect === 'wipeRight' && `
    transition: transform ${timeout}ms ease-in-out;
    transform-origin: 100% 50%;
    transform: scaleX(0);
    ${overlay && `
      transform: scaleX(1);
      transform-origin: 0% 50%;
    `}
  `}
  ${({ overlay, effect }: any) => effect === 'wipeLeft' && `
    transition: transform ${timeout}ms ease-in-out;
    transform-origin: 0% 50%;
    transform: scaleX(0);
    ${overlay && `
      transform: scaleX(1);
      transform-origin: 100% 50%;
    `}
  `}
`;

const transitionColors = [
    colors.bgColor
];

// var overlayColor = transitionColors[Math.floor(Math.random() * transitionColors.length)];

class PageTransition extends React.PureComponent<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            overlay: false, // true If you want to transition on page load
            pathname: null,
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.location.pathname !== prevState.pathname) { // Use If you want NO transition on page load
        // if (nextProps.location.pathname !== prevState.pathname || !prevState.pathname) { // Use If you want to transition on page load
            return {
                overlay: prevState.pathname, // prevState.pathname If you want NO transition on page load
                pathname: nextProps.location.pathname,
                overlayColor: transitionColors[Math.floor(Math.random() * transitionColors.length)]
            };
        }
        return null;
    }

  handleEntered = (isAppearing) => {
      setTimeout(() => {
          this.setState({ overlay: false });
      }, hang);
  }

  render() {
      const { children, location, overlayColor: overlayColorProp } = this.props;
      const { overlay } = this.state;

      const overlayColor = overlayColorProp || colors.bgColor;
      const transitionEffect = 'fade';

      return (
          <React.Fragment>
              <TransitionGroup>
                  <Transition
                      key={location.pathname}
                      unmountOnExit={true}
                      appear={false}
                      timeout={{
                          enter: timeout,
                          exit: timeout,
                      }}
                  >
                      {(status) => (
                          <PageContent transitionStatus={status}>
                              {children}
                          </PageContent>
                      )}
                  </Transition>
              </TransitionGroup>

              <Transition
                  in={overlay}
                  appear={false} // true If you want to transition on page load
                  timeout={{
                      enter: timeout,
                      exit: timeout,
                      appear: timeout * 2
                  }}
                  onEntered={this.handleEntered}
              >
                  {(status) => (
                      <TransitionOverlay
                          style={{
                              position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', background: overlayColor, zIndex: 20
                          }}
                          effect={transitionEffect}
                          overlay={overlay}
                          transitionStatus={status}
                          bgColor={overlayColor}
                      />
                  )}
              </Transition>
          </React.Fragment>
      );
  }
}

export default PageTransition;
